<div class="modal-content">
    <div class="modal-body">
        <div class="flex success">

            <img title="Product Created" alt="Product Created" src="assets/imgs/tick-round-green.svg">

            <p>Your Product Successfully Created</p>

            <button type="button" (click)="close()">Okay</button>

        </div>
    </div>
</div>