<div class="payout-wrapper">
    <div class="container">
        <h1 class="text-title">Payout</h1>
 
        <!--Stripe Account-->
        <div class="top-section account-section">
            <div class="pay-card">
                <h2 class="text-title" style="color: #fff !important">Account Balance</h2>
                <div class="dollar-icon">
                    <img src="../../../../../../assets/images/dollar-sign-green.svg" alt="">
                </div>
                <div class="amount">
                    <h3 class="price">
                        $ {{accountBalance | number : '1.2-2'}}
                    </h3>
                    <div class="text">Available</div>
                </div>
            </div>
            <div class="card-details stripe-account" *ngIf="!isBankList">
                <div *ngIf="!isPending">
                    <div class="top-wrapper">
                        <div class="top-title"> 
                            <h3>Stripe Account</h3>
                            <span class="right-text">
                                For cash out you need to add stripe account first
                            </span>
                        </div>
                        <div class="acc-tab">
                            <button class="tab-btn-wrp active" *ngIf = "isPersonal && !isPersonalDone">
                                <span class="tab-btn">1</span>
                                Personal Information
                                <span class="border"></span>
                            </button>
                            <button class="tab-btn-wrp check" *ngIf = "isPersonalDone && !isPersonal">
                                <span class="tab-btn">1</span>
                                Personal Information
                                <span class="border"></span>
                            </button>
                            <button class="tab-btn-wrp" *ngIf="isPersonal && !isPersonalDone">
                                <span class="tab-btn">2</span>
                                Account Details
                            </button>
                            <button class="tab-btn-wrp active" *ngIf="isPersonalDone && !isPersonal">
                                <span class="tab-btn">2</span>
                                Account Details
                            </button>
                        </div>
                        <div class="card-content" *ngIf = "isPersonalDone && !isPersonal">
                            <div class="text-title">Account Details</div>
                            <div class="card-type">
                                <!-- <div class="ac-btn">
                                    <input type="radio" id="PayPal" name="bank" [checked]="isCardChecked" (change)="selectedCard()">
                                    <label for="PayPal">Card</label>
                                </div> -->
                                <div class="ac-btn active">
                                    <input type="radio" id="bank-ac" name="bank" [checked]="isBankChecked" (change)="selectedBank()">
                                    <label for="bank-ac">Bank Account</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- from-01 -->
                    <div class="form-section" *ngIf = "(isPersonal && !isPersonalDone)">
                        <form [formGroup]="personalFormGroup">
                            <div class="form-container">
                                <div class="form-wrapper">
                                    <div class="form-col">
                                        <label for="">First Name*</label>
                                        <input type="text" class="control-input" placeholder="Enter first name" required (keypress)="characterOnly($event)" formControlName="firstName">
                                        <span class="text-danger" *ngIf="personalFormGroup.controls.firstName.touched">
                                            <span *ngIf="personalFormGroup.controls['firstName'].hasError('required')">
                                                First name is required* 
                                            </span>
                                            <span class="" *ngIf="personalFormGroup.controls.firstName.errors?.['minlength']">
                                                The first name must be at least three characters long.
                                            </span>
                                            <span class="" *ngIf="personalFormGroup.controls.firstName.errors?.['maxlength']">
                                                The first name cannot exceed 25 characters.
                                            </span>
                                        </span>
                                    </div>                                    
                                    <div class="form-col">
                                        <label for="">Last Name*</label>
                                        <input type="text" class="control-input" placeholder="Enter last name" required (keypress)="characterOnly($event)" formControlName="lastName">
                                        <span class="text-danger" *ngIf="personalFormGroup.controls.lastName.touched">
                                            <span *ngIf="personalFormGroup.controls['lastName'].hasError('required')">
                                                Last name is required* 
                                            </span>
                                            <span class="" *ngIf="personalFormGroup.controls.lastName.errors?.['minlength']">
                                                The last name must be at least three characters long.
                                            </span>
                                            <span class="" *ngIf="personalFormGroup.controls.lastName.errors?.['maxlength']">
                                                The last name cannot exceed 25 characters.
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="form-wrapper">
                                    <div class="form-col">
                                        <label for="">Email Address*</label>
                                        <input type="text" class="control-input" placeholder="Enter email address" required readonly formControlName="emailAddress">
                                        <span class="text-danger" *ngIf="personalFormGroup.controls.emailAddress.touched">
                                            <span *ngIf="personalFormGroup.controls['emailAddress'].hasError('required')">
                                                Email is required* 
                                            </span>
                                            <span class="" *ngIf="personalFormGroup.controls['emailAddress'].hasError('invalidEmail')">
                                                Invalid email
                                            </span>
                                        </span>
                                    </div>
                                    <div class="form-col">
                                        <label for="">SSN* <span>Last 4 Digit</span></label>
                                        <input type="text" class="control-input" placeholder="Enter Last 4 Digit of ssn number" (keypress)="numberOnly($event)" formControlName="ssnNumber">
                                        <span class="text-danger" *ngIf="personalFormGroup.controls.ssnNumber.touched">
                                            <span *ngIf="personalFormGroup.controls['ssnNumber'].hasError('required')">
                                                Snn number is required*
                                            </span>
                                            <span class="" *ngIf="personalFormGroup.controls.ssnNumber.errors?.['minlength']">
                                                The ssn number must be at least 4 digit.
                                            </span>
                                            <span class="" *ngIf="personalFormGroup.controls.ssnNumber.errors?.['maxlength']">
                                                The ssn number cannot exceed more than 4 digit.
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="form-wrapper">
                                    <div class="form-col">
                                        <label for="">Date Of Birth*</label>
                                        <div class="dob">
                                           <div class="dob-content">
                                                <select class="control-input" placeholder=" Month " value="" [(ngmodel)]="selectedMonthId" formControlName="selectedMonth" (change)="selectChangeMonth($event)">
                                                    <option value="">
                                                        Month
                                                    </option>
                                                    <ng-container *ngFor="let month of monthList">
                                                        <option [value]="month.id">
                                                            {{month.title}}
                                                        </option>
                                                    </ng-container>
                                                </select> 
                                                <span class="text-danger" *ngIf="personalFormGroup.controls.selectedMonth.touched">
                                                    <span *ngIf="personalFormGroup.controls['selectedMonth'].hasError('required')">
                                                        Month is required*
                                                    </span>
                                                </span>
                                           </div>
                                            <div class="dob-content">
                                                <select class="control-input" placeholder=" Day " value="" [(ngmodel)]="selectedDateId" formControlName="selectedDate" (change)="selectChangeDate($event)">
                                                    <option value="">
                                                        Day
                                                    </option>
                                                    <ng-container *ngFor="let day of dayList">
                                                        <option [value]="day.id">
                                                            {{day.id}}
                                                        </option>
                                                    </ng-container>
                                                </select>
                                                <span class="text-danger" *ngIf="personalFormGroup.controls.selectedDate.touched">
                                                    <span *ngIf="personalFormGroup.controls['selectedDate'].hasError('required')">
                                                    Day is required*
                                                    </span>
                                                </span>
                                                <span class="text-danger" *ngIf="isValidMonth">
                                                    Please select day less than 30.
                                                </span>
                                                <span class="text-danger" *ngIf="isValidMonthValue">
                                                    Please select day less than 31.
                                                </span>
                                            </div>
                                            <div class="dob-content">
                                                <select class="control-input" placeholder=" Year " value="" [(ngmodel)]="selectedYearId" formControlName="selectedYear" (change)="selectChangeYear($event)">
                                                    <option value="">
                                                        Year
                                                    </option>
                                                    <ng-container *ngFor="let year of yearList">
                                                        <option [value]="year.id">
                                                            {{year.id}}
                                                        </option>
                                                    </ng-container>
                                                </select>
                                                <span class="text-danger" *ngIf="personalFormGroup.controls.selectedYear.touched">
                                                    <span *ngIf="personalFormGroup.controls['selectedYear'].hasError('required')">
                                                        Year is required*
                                                    </span>    
                                                </span>
                                                <span class="text-danger" *ngIf="isValidYear">
                                                    This year is not a leap year.
                                                </span> 
                                            </div>
                                        </div>                       
                                    </div>
                                    <div class="form-col contect-num">
                                        <label for="">Contact Number*</label>
                                        <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="[CountryISO.UnitedStates]" 
                                            [enableAutoCountrySelect]="false" [enablePlaceholder]="true"
                                            [searchCountryFlag]="true" [selectFirstCountry]="false" [maxLength]="10"
                                            [searchCountryField]="[ SearchCountryField.Iso2, SearchCountryField.Name]" (countryChange)="onCountryChange($event)"
                                            [selectedCountryISO]="CountryISO.UnitedStates"  [phoneValidation]="true"
                                            [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="phoneNumber">
                                        </ngx-intl-tel-input>
                                        <span class="text-danger" *ngIf="personalFormGroup.controls.phoneNumber.touched">
                                            <span *ngIf="personalFormGroup.controls['phoneNumber'].hasError('required')">
                                                Phone number is required*
                                            </span>
                                            <!-- <span *ngIf="personalFormGroup.controls['phoneNumber'].invalid">
                                                Please enter minimum 10 digit
                                            </span> -->
                                        </span>
                                    </div>
                                </div>
                                <div class="form-wrapper">
                                    <div class="form-col">
                                        <label for="">Address Line 1*</label>
                                        <input type="text" class="control-input" placeholder="Enter address line 1" required  formControlName="addressLine1">
                                        <span class="text-danger" *ngIf="personalFormGroup.controls.addressLine1.touched">
                                            <span *ngIf="personalFormGroup.controls['addressLine1'].hasError('required')">
                                                Address line 1 is required* 
                                            </span>
                                            <span class="" *ngIf="personalFormGroup.controls.addressLine1.errors?.['minlength']">
                                                The address line 1 must be at least five characters long.
                                            </span>
                                            <span class="" *ngIf="personalFormGroup.controls.addressLine1.errors?.['maxlength']">
                                                The address line 1 cannot exceed 25 characters.
                                            </span>
                                        </span>
                                    </div>
                                    <div class="form-col">
                                        <label for="">Address Line 2*</label>
                                        <input type="text" class="control-input" placeholder="Enter address line 2"  required  formControlName="addressLine2">
                                        <span class="text-danger" *ngIf="personalFormGroup.controls.addressLine2.touched">
                                            <span *ngIf="personalFormGroup.controls['addressLine2'].hasError('required')">
                                                Address line 2 is required* 
                                            </span>
                                            <span class="" *ngIf="personalFormGroup.controls.addressLine2.errors?.['minlength']">
                                                The address line 2 must be at least five characters long.
                                            </span>
                                            <span class="" *ngIf="personalFormGroup.controls.addressLine2.errors?.['maxlength']">
                                                The address line 2 cannot exceed 25 characters.
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="form-wrapper">
                                    <div class="form-col">
                                        <label for="">Country*</label>
                                        <select name="date" id="date" disabled = "true" readonly>
                                            <option value="" selected disabled hidden>
                                                United States
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-col Postal-wrp">
                                        <label for="">Postal Code*</label>
                                        <input type="text" class="control-input" placeholder="Enter postel code" min="4" (keypress)="numberOnlyPostalCode($event)" formControlName="postalCode">
                                        <div *ngIf="isPostal">
                                            <ul class="postel" name="date" id="date">
                                                <li class="blazing" *ngFor="let postal of postalList" (click)="selectChangePostel(postal)">
                                                    {{postal.code}}
                                                </li>
                                            </ul>
                                        </div>
                                        <span class="text-danger" *ngIf="personalFormGroup.controls.postalCode.touched">
                                            <span *ngIf="personalFormGroup.controls['postalCode'].hasError('required')">
                                                Postel code is required*
                                            </span>
                                            <span class="" *ngIf="personalFormGroup.controls.postalCode.errors?.['minlength']">
                                                The postel code must be at least 3 digit.
                                            </span>
                                            <span class="" *ngIf="personalFormGroup.controls.postalCode.errors?.['maxlength']">
                                                The postel code cannot exceed more than 5 digit.
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="form-wrapper">
                                    <div class="form-col">
                                        <label for="">City*</label>  
                                        <input type="text" class="control-input" placeholder="Enter city name" required (keypress)="characterOnly($event)" readonly formControlName="cityName">
                                        <span class="text-danger" *ngIf="personalFormGroup.controls.cityName.touched">
                                            <span *ngIf="personalFormGroup.controls['cityName'].hasError('required')">
                                                City name is required* 
                                            </span>
                                        </span>                                  
                                    </div>
                                    <div class="form-col">
                                        <label for="">State*</label> 
                                        <input type="text" class="control-input" placeholder="Enter state name" required (keypress)="characterOnly($event)"  readonly formControlName="stateName">
                                        <span class="text-danger" *ngIf="personalFormGroup.controls.stateName.touched">
                                            <span *ngIf="personalFormGroup.controls['stateName'].hasError('required')">
                                                State name is required* 
                                            </span>
                                        </span>                                   
                                    </div>
                                </div>
                            </div>
                            <div class="form-action">
                                <button type="button" class="primary-btn"  (click)="submitInformation()">
                                    Save & Next
                                </button>
                            </div>
                        </form>
                    </div>
    
                    <!-- from-02  for Bank-->
                    <div class="form-section" *ngIf="(isPersonalDone && !isPersonal && isBankChecked)">
                        <form [formGroup]="addBankFormGroup">
                            <div class="form-container bank-container">
                                <div class="top" >
                                    <div class="left-part">
                                        <div class="form-col">
                                            <label for="">Bank Name*</label>
                                            <input type="text" class="control-input" placeholder="Enter bank name" required formControlName="bankName"  (keypress)="characterOnly($event)">
                                            <span class="text-danger" *ngIf="addBankFormGroup.controls.bankName.touched">
                                                <span *ngIf="addBankFormGroup.controls['bankName'].hasError('required')">
                                                    Bank name is required* 
                                                </span>
                                                <span class="" *ngIf="addBankFormGroup.controls.bankName.errors?.['minlength']">
                                                    The bank name must be at least five characters long.
                                                </span>
                                                <span class="" *ngIf="addBankFormGroup.controls.bankName.errors?.['maxlength']">
                                                    The bank name cannot exceed 25 characters.
                                                </span>
                                            </span>
                                        </div>
                                        <div class="form-col">
                                            <label for="">Bank Account Number*</label>
                                            <input type="text" class="control-input" placeholder="Enter account number" onpaste="return false;" required formControlName="accNumber" (keypress)="numberOnly($event)">
                                            <span class="text-danger" *ngIf="addBankFormGroup.controls.accNumber.touched">
                                                <span *ngIf="addBankFormGroup.controls['accNumber'].hasError('required')">
                                                    Account number is required* 
                                                </span>
                                                <span class="" *ngIf="addBankFormGroup.controls.accNumber.errors?.['minlength']">
                                                    The account number must be at least 7 digit.
                                                </span>
                                                <span class="" *ngIf="addBankFormGroup.controls.accNumber.errors?.['maxlength']">  
                                                    The account number cannot exceed more than 15 digit.
                                                </span>
                                            </span>
                                        </div>
                                        <div class="form-col">
                                            <label for="">Routing Number*</label>
                                            <input type="text" class="control-input" placeholder="Enter routing number" onpaste="return false;" required formControlName="routingNumber" (keypress)="numberOnly($event)">
                                            <span class="text-danger" *ngIf="addBankFormGroup.controls.routingNumber.touched">
                                                <span *ngIf="addBankFormGroup.controls['routingNumber'].hasError('required')">
                                                    Routing Number is required* 
                                                </span>
                                                <span class="" *ngIf="addBankFormGroup.controls.routingNumber.errors?.['minlength']">
                                                    The routing number must be at least 9 digit.
                                                </span>
                                                <span class="" *ngIf="addBankFormGroup.controls.routingNumber.errors?.['maxlength']">
                                                    The routing number cannot exceed more than 9 digit. 
                                                </span>
                                            </span>
                                        </div>
                                    </div>  
                                    <div class="left-part right-part">
                                        <div class="bank-cheque">
                                            <label for="">Information</label>
                                            <img src="../../../../../../assets/images/bank-cheque.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="terms-con">
                                    <input type="checkbox" id="terms" name="terms" value="terms" (change)="changeEvent($event)">
                                    <label for="terms">
                                        I have read & agreed with terms & conditions of 
                                        <span class="blazing" (click)="terms()">
                                            kell-Commerce Agreement.
                                        </span>
                                    </label>
                                </div>
                            </div>  
                            <div class="form-action">
                                <button class="primary-btn" (click)="previousDetails()">Previous</button>
                                <button type="button" class="primary-btn" [disabled]="(!addBankFormGroup.valid) || !isChecked" (click)="addBankAccount()">Submit</button>
                            </div>
                        </form>
                    </div> 
                    
                    <!-- form-03  -->
                    <!-- <div class="form-section" *ngIf=" isPersonalDone && !isPersonal && isCardChecked">
                        <form [formGroup]="addCardFormGroup">
                            <div class="form-container">
                                <div class="form-wrapper">
                                    <div class="form-col">
                                        <label for="">Card Number*</label>
                                        <input type="text" class="control-input" placeholder="Enter card number" min="16" max="16" minlength="16" maxlength="16" required formControlName="cardNumber" (keypress)="numberOnly($event)">
                                        <span class="text-danger" *ngIf="addCardFormGroup.controls.cardNumber.touched">
                                            <span *ngIf="addCardFormGroup.controls['cardNumber'].hasError('required')">
                                                Card number is required* 
                                            </span>
                                            <span class="" *ngIf="addCardFormGroup.controls.cardNumber.errors?.['minlength']">
                                                The card number must be at least 16 digit.
                                            </span>
                                            <span class="" *ngIf="addCardFormGroup.controls.cardNumber.errors?.['maxlength']">  
                                                The card number cannot exceed more than 16 digit.
                                            </span>
                                        </span>
                                    </div>
                                    <div class="form-col">
                                        <label for="">Expiration*</label>
                                        <input type="text"  class="control-input" placeholder="MM/YYYY" maxlength="7" appInputMaskDirective required formControlName="expDate" (keypress)="numberOnlyExp($event)">
                                        <span class="text-danger" *ngIf="addCardFormGroup.controls.expDate.touched">
                                            <span *ngIf="addCardFormGroup.controls['expDate'].hasError('required')">
                                                Exp Date is required* 
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="form-wrapper">
                                    <div class="form-col">
                                        <label for="">CVV*</label>
                                        <input type="password" class="control-input" placeholder="Enter here" min="3" max="3" minlength="4" maxlength="4" required formControlName="cvvNumber" (keypress)="numberOnly($event)">
                                        <span class="text-danger" *ngIf="addCardFormGroup.controls.cvvNumber.touched">
                                            <span *ngIf="addCardFormGroup.controls['cvvNumber'].hasError('required')">
                                                Cvv number is required* 
                                            </span>
                                            <span class="" *ngIf="addCardFormGroup.controls.cvvNumber.errors?.['minlength']">
                                                The cvv number must be at least 3 digit.
                                            </span>
                                            <span class="" *ngIf="addCardFormGroup.controls.cvvNumber.errors?.['maxlength']">  
                                                The cvv number cannot exceed more than 4 digit.
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="terms-con">
                                    <input type="checkbox" id="terms" name="terms" value="terms" (change)="changeEventAggrement($event)">
                                    <label for="terms">
                                        I have read & agreed with terms & conditions of 
                                        <span class="blazing" (click)="condition()">
                                            Blazing Cards Agreement.
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-action">
                                <button class="border-btn" (click)="previousCardDetails()">Previous</button>
                                <button type="button" class="primary-btn" [disabled]="(!addCardFormGroup.valid) || !isCheckedAggrement" (click)="addCardAccount()">Submit</button>
                            </div>
                        </form>
                    </div> -->
                </div>
                
                <!-- form-o4 -->
                <div class="form-section submit-aap" *ngIf="isPending">
                    <h3>Application Submitted</h3>
                    <div class="hour-glass">
                        <img src="../../../../../../assets/images/hourglass.svg" alt="">
                    </div>
                    <h4>Thank you for filling out the Application!</h4>
                    <div class="text">Please allow the Stripe Team, 2-3 working days to review your Application.</div>
               </div>
            </div>
            <div class="card-details"  *ngIf="isBankList">
                <div class="card-content">
                    <div class="text-title">Cash Out</div>
                    <div class="card-type">                            
                        <!-- <div class="ac-btn active">
                            <input type="radio" id="bank-ac" name="bank" value="bank-ac" [checked]="isCardCheckedCashOut" (change)="selectedCardCashOut()">
                            <label for="bank-ac">Card</label>
                        </div> -->
                        <div class="ac-btn active">
                            <input type="radio" id="bank-ac" name="bank" value="bank-ac" [checked]="isBankCheckedCashOut" (change)="selectedBankCashOut()">
                            <label for="bank-ac">Bank Account</label>
                        </div>
                    </div>
                </div>
                <div class="bottom-content">           
                    <div class="left-part" *ngIf="isBankCheckedCashOut">
                        <div class="bank-title"> <label for="bank">Bank Account</label>
                            <button (click)="addAnotherBankAccount()" class="add_bank-bnt">
                                Add bank
                            </button>
                        </div>
                        <div class="select-cal">
                            <ng-container *ngIf="bankList.length > 0">
                                <ng-select [items]="bankList" bindLabel="bankName" bindValue="bankName" placeholder="Select Bank Account" [searchable]="false" [clearable]="false" [(ngModel)]="placeHolderName" [ngModelOptions] = "{standalone : true}" (change)="onBankListChange($event)">
                                    <ng-template ng-label-tmp let-item="item" >
                                        {{item.bankName}} {{item.last4}} 
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                    {{item.bankName}} {{item.last4}}
                                        <span class="dlt-btn" (click)="deleteBankAccount(item)">
                                            <img src="../../../../../../assets/images/delete-btn.svg" alt="">
                                        </span>
                                    </ng-template>
                                </ng-select>
                            </ng-container>
                        </div>                        
                    </div>
                    <div class="left-part" *ngIf="isCardCheckedCashOut">
                        <div class="bank-title"> 
                            <label for="bank">Card</label>
                            <button (click)="addAnotherCardAccount()" class="add_bank-bnt">
                                Add debit card
                            </button>
                        </div>
                        <ng-container *ngIf="cardList.length > 0">
                            <ng-select [items]="cardList" bindLabel="name" bindValue="cardId" placeholder="{{cardIdNumber}}" [searchable]="false" [clearable]="false" [(ngModel)]="cardIdNumber" (change)="onCardListChange($event)"> 
                                <ng-template ng-label-tmp let-item="item">
                                    {{item.last4}}
                                    <img src="../../../../../../../assets/images/dankort.svg" alt="" *ngIf="item.cardBrand === 'dankort'">
                                    <img src="../../../../../../../assets/images/diners-club-international.svg" alt="" *ngIf="item.cardBrand === 'diners club'">
                                    <img src="../../../../../../../assets/images/discover.svg" alt="" *ngIf="item.cardBrand === 'discover'">
                                    <img src="../../../../../../../assets/images/jcb.svg" alt="" *ngIf="item.cardBrand === 'jcb'">
                                    <img src="../../../../../../../assets/images/maestro.svg" alt="" *ngIf="item.cardBrand === 'mastercard'">
                                    <img src="../../../../../../../assets/images/unionpay.svg" alt="" *ngIf="item.cardBrand === 'unionpay'">
                                    <img src="../../../../../../../assets/images/visa card.svg" alt="" *ngIf="item.cardBrand === 'Visa'">
                                    <img src="../../../../../../../assets/images/Amex.svg" alt="" *ngIf="item.cardBrand === 'amex'">
                                </ng-template>                            
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                    {{item.last4}}
                                    <img src="../../../../../../../assets/images/dankort.svg" alt="" *ngIf="item.cardBrand === 'dankort'">
                                    <img src="../../../../../../../assets/images/diners-club-international.svg" alt="" *ngIf="item.cardBrand === 'diners club'">
                                    <img src="../../../../../../../assets/images/discover.svg" alt="" *ngIf="item.cardBrand === 'discover'">
                                    <img src="../../../../../../../assets/images/jcb.svg" alt="" *ngIf="item.cardBrand === 'jcb'">
                                    <img src="../../../../../../../assets/images/maestro.svg" alt="" *ngIf="item.cardBrand === 'mastercard'">
                                    <img src="../../../../../../../assets/images/unionpay.svg" alt="" *ngIf="item.cardBrand === 'unionpay'">
                                    <img src="../../../../../../../assets/images/visa card.svg" alt="" *ngIf="item.cardBrand === 'Visa'">
                                    <img src="../../../../../../../assets/images/Amex.svg" alt="" *ngIf="item.cardBrand === 'amex'">
                                    <span class="dlt-btn" (click)="deleteCard(item)">
                                        <img src="../../../../../../assets/images/delete-btn.svg" alt="">
                                    </span>
                                </ng-template>
                            </ng-select>
                        </ng-container>
                    </div>
                    <div class="right-part">
                        <div class="cash-amount">
                            <label for="">Cash-Out Amount</label>
                            <input type="text" class="control-input" placeholder="Enter cash out amount" (keypress)="numberOnly($event)" [(ngModel)]="cashOutAmount">
                        </div>
                        <button class="cash-out" *ngIf="isCardCheckedCashOut && !cashOutAmount && !cardIdNumber">Cash Out</button>
                        <button class="cash-out" *ngIf="isCardCheckedCashOut && !cashOutAmount && cardIdNumber">Cash Out</button>
                        <button class="cash-out cash-out-highlight" *ngIf="isCardCheckedCashOut && cashOutAmount && cardIdNumber" (click)="cashOutCard()">Cash Out</button>
                        <button class="cash-out" *ngIf="isBankCheckedCashOut && !cashOutAmount && !bankIdNumber">Cash Out</button>
                        <button class="cash-out" *ngIf="isBankCheckedCashOut && !cashOutAmount && bankIdNumber">Cash Out</button>
                        <button class="cash-out cash-out-highlight" *ngIf="isBankCheckedCashOut && cashOutAmount && bankIdNumber" (click)="cashOutBank()">Cash Out</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="pay-history" *ngIf="isBankList">
            <!-- start of table-list -->
            <h2 class="text-title">Payout History</h2>
            <div class="table-data-details">
                <table class="table-list">
                    <thead class="table-head">
                        <tr>
                            <th>Payout Reference</th>
                            <th>Amount ($)</th>
                            <th>Status</th>
                            <th>Type</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody class="table-body">
                        <tr *ngFor="let payout of payoutList">
                            <td>
                                {{payout.referenceId}}
                            </td>
                            <td>
                                {{payout.requestBalance | number : '1.2-2'}}
                            </td>
                            <td *ngIf="payout.status == '1'">
                                Pending
                            </td>
                            <td *ngIf="payout.status == '2'">
                                Processing
                            </td>
                            <td *ngIf="payout.status == '3'">
                                Rejected
                            </td>
                            <td *ngIf="payout.status == '4'">
                                Completed
                            </td>
                            <td *ngIf="payout.type == '1'">
                                Card
                            </td>
                            <td *ngIf="payout.type == '2'">
                                Bank account
                            </td>
                            <td>
                                {{payout.createdDate | date : 'mediumDate'}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="no-list" *ngIf="payoutList.length === 0">
                <div class="no-data">
                    <img src="../../../../../../assets/images/no-data.svg" alt="">
                </div>
                <span class="worn">No Data Found</span>
            </div>
            <!-- end of table-list -->
        </div>
    </div>