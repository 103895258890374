import { Component, OnInit,ViewChild, ChangeDetectorRef } from '@angular/core';
import {ShipmentService} from '../../../../../../core/shipments/shipment.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-shipments',
  templateUrl: './shipments.component.html',
  styleUrls: ['./shipments.component.scss']
})
export class ShipmentsComponent implements OnInit {
  public shipmentList : []
  public totalCount : any
  public countOrder = 10
  public filterForm: FormGroup;
  public filter = false;
  public miniDate: any;
  public dateError: string;
  public startDate: any;
  public isRequired = false;
  public endDate: any;
  @ViewChild(NgbDropdown)
  public dropdown: NgbDropdown;
  public keyword = '';
  public earningsArray = [];
  public filterData: any = [];
  public filterDataId = [];
  public selectedAll: any;
  public limit = 10;
  public offset = 0;
  public currentPage = 1;
  public userDetails = JSON.parse(sessionStorage.getItem('vendor-settings'));
  public currencyCode ;
  public queryData: any = {};
  public labelUrl: string = environment.labelUrl;
  constructor(
    public service : ShipmentService,
    public toastr: ToastrService,
    public ref : ChangeDetectorRef
    
    
    
  ) { }

  ngOnInit(): void {
    let params  = {}
    this.getshipmentList()
    this.getshipmentCount()
  }
  getshipmentCount() {
    this.service.ShipmentCount().subscribe((data :any) => {
      if(data) {
        console.log(data)
        // this.shipmentList = data.data;
        this.totalCount = data.data;
        this.ref.detectChanges();
      }
    });
  }
  getshipmentList() {
    let params = {
      limit : this.limit,
      offset : this.offset,
      count : false
    }
    this.service.ShipmentList(params).subscribe((data :any) => {
      if(data) {
         data.data.forEach(element => {
          element["shipment_id"] = element.id
          
        });
        this.shipmentList = data.data
        console.log(this.shipmentList)
        // this.totalCount = data.totalCount;
        this.ref.detectChanges();
      }
    });
  }
  pageChange(event) {
    this.currentPage = event;
    this.offset = this.limit * (event - 1);
    this.getshipmentList();
  }
  search(event) {

  }
  generateLabel(shipmentId: number, customerId: number,orderId : number) {
    const date = new Date();
    let params = {
      // "vendorId": JSON.parse(localStorage.getItem('vendorUser')).vendorId,
      // "shipDate": date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate(),
      "customerId":customerId,
      "shipmentId":shipmentId,
      "orderId" : orderId
    }
    this.service.generateLabel(params).subscribe(result => {
      if(result && result.status === 1) {
        this.shipmentList = []
        // this.getShipmentData(this.streamId, this.limit, this.offset);
        // this.getStreamListCount(this.streamId);
        this.toastr.success(result.message);
        console.log(result);
        this.ref.detectChanges();
        this.getshipmentList()
      } 
      else {
        // this.toastr.error(result.message);
      }
    });
  }
  printLabel(shipmentId) {
    this.service.printLabel(shipmentId).subscribe(data => {
      if(data && data.status === 1) {
        window.open(data?.data?.label, '_blank');
      }
    });
  }


}
