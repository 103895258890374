import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[formControlName][appInputMaskDirective]'
})

export class InputMaskDirectiveDirective {

  @HostListener('input', ['$event'])
  
  onKeyDown(event: KeyboardEvent) {
    
    const input = event.target as HTMLInputElement;

    const trimmed = input.value.replace(/\s+/g, '').slice(0, input.value.indexOf('/') == -1 ? 6 : 7);
    if (trimmed.length > 4) {
      return (input.value = `${trimmed.slice(0, 2)}/${trimmed.slice(trimmed.indexOf('/') == -1 ? 2 : 3)}`);
    }
  }
}