<app-breadcrumbs></app-breadcrumbs>
<div class="product-list-wrap" style="margin-top: 55px;">


    <div class="allorders-filter-row flex">

        <div class="aofrr-search">
            <input type="text" placeholder="Search" class="form-input search" (keydown.enter)="search($event.target.value)">
        </div>
        
        <div class="aofr-rht flex">

            <div *ngIf="filterDataId.length >0" class="search-right flex" (click)="exportEarnings()" [title]=" filterDataId.length === 0 ? 'Bulk export' : 'Export'">
                <button class="button-reg secondary"> <img title="Export" alt="Export" src="assets/imgs/export.svg" style="width:16px"> Export</button>
                <span class="multi-spin" *ngIf="(paymentSandbox.exportEarningLoading$ | async) || (paymentSandbox.MultipleEarningExportLoading$ | async)"><i class="fas fa-spinner fa-spin fa-2x" style="color: blue;"></i></span>
            </div>
            <div *ngIf="filterDataId.length === 0" class="search-right flex" (click)="exportEarnings()" [title]=" filterDataId.length > 0 ? 'Bulk export' : 'Export'">
                <button class="button-reg secondary"> <img title="Export" alt="Export" src="assets/imgs/export.svg" style="width:16px">Export All</button>
                <span class="multi-spin" *ngIf="(paymentSandbox.exportEarningLoading$ | async) || (paymentSandbox.MultipleEarningExportLoading$ | async)"><i class="fas fa-spinner fa-spin fa-2x" style="color: blue;"></i></span>
            </div>
            
            <!-- <div class="dropdown">
                <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Filters <img src="assets/imgs/header-icons/arrow-down.svg" alt="">
                </button>
                <div class="dropdown-menu  dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <form action="" class="flex" >
                        <div class="input-group ig-date">
                            <p>From Date</p>
                            <input type="text" readonly  class="date"  autocomplete="off" (dateSelect)="onDateSelect($event)" name="dp" id="cal-in" ngbDatepicker #f="ngbDatepicker" formControlName="fromDate" [minDate]="" [maxDate]="minPickerDate" (click)="f.toggle()">
                        </div>
                        <div class="input-group ig-date">
                            <p>To Date</p>
                            <input type="text" readonly class="date" name="dp" autocomplete="off" id="cal-in" ngbDatepicker #d="ngbDatepicker" formControlName="toDate" [ngClass]="{'disabled': dateError !== ''}" [minDate]="filterForm.value.fromDate" [maxDate]="minPickerDate" (click)="setMinValue(d)">
                        </div>
                         <div class="error" style="color: red;position:absolute;margin-left:505px;margin-bottom:-19px;">
                            {{dateError}}
                        </div> 
                        <div class="error" *ngIf="isRequired" style="color: red;position:absolute;margin-left:505px;margin-bottom:-19px;">
                            To Date is required
                        </div> 
                        <button class="button-reg primary">Apply Filters</button>
                        <a class="reset-filter">Reset</a>
                    </form>
                </div>
            </div> -->

            

        </div>
    </div>

    <div class="loading" *ngIf="(paymentSandbox.categoryListLoading$ | async)">
        <app-loader></app-loader>
    </div>
    <!-- <div class="no-data" *ngIf="(paymentSandbox.categoryList$ | async)?.length === 0 && (paymentSandbox.categoryListLoaded$ | async)">
        <span>No Earnings found</span> </div> -->
    <div class="table-responsive ">
        <table class="table">
            <thead>
                <tr>
                    <!-- <th>
                        <div title="Click To Export" class="form-group" title="Click To Export">
                            <input type="checkbox" id="checkbox1" [(ngModel)]="selectedAll" (change)="selectAll($event)" [ngModelOptions]="{standalone: true}">
                            <label for="checkbox1"></label>
                        </div>
                    </th> -->
                    <th width="15%">Order ID</th>
                    <th class="text-center">Date & Time</th>
                    <th class="text-center">Buyer</th>
                    <th class="text-center">Amount</th>
                    <!-- <th class="text-center">Tax</th> -->
                    <th class="text-center">Shipping(-)</th>
                    <th class="text-center">Net Amount</th>
                    <th class="text-center">Commission(-)</th>
                    <th class="text-center">Seller Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let array of CommissionData let i = index;">
                    <!-- <td>
                        <div title="Click To Export" class="form-group" title="Click To Export">
                            <input type="checkbox" [id]="array.productId" class="custom-control-input chk" [(ngModel)]="array.selected" (change)="checkIfAllSelected()" [value]="array.productId" [ngModelOptions]="{standalone: true}">
                            <label [for]="array.productId"></label>
                        </div>
                    </td> -->
                    <td (click)="vieworder(array.orderId)" class="cursor">
                        <span class="id">{{array.subOrderId}}</span>
                    </td>
                    <!-- <td>
                        <span class="id">Kell-202304042354251</span>
                    </td> -->
                    <td class="text-center"> {{array.createdDate | date : 'MMM d, y, h:mm a'}} </td>
                    <td class="text-center"> 
                        <div class="flex buyer">
                        <!-- <span class="flex logo">{{((array?.customerFirstName).charAt(0)) | uppercase}}</span> -->
                        <p><span class="name">{{array?.customerFirstName}}</span><br>{{array?.shippingCity}}, {{array?.shippingCountry}}
                        </p>
                    </div>
                </td>
                    <td class="text-center"> {{array?.currencySymbolLeft}}{{ array.amount}}{{array?.currencySymbolRight}}</td>
                    <!-- <td class="text-center"> {{array?.currencySymbolLeft}}{{array.tax}}{{array?.currencySymbolRight}}</td> -->
                    <td class="text-center"> {{array?.currencySymbolLeft}}{{array.shippingCharge}}{{array?.currencySymbolRight}}</td>
                    <td class="text-center"> {{array?.currencySymbolLeft}}{{array.NetAmount}}{{array?.currencySymbolRight}}</td>
                    <td class="text-center"> {{array?.currencySymbolLeft}}{{array.totalCommission}}{{array?.currencySymbolRight}}</td>
                    <td class="text-center"> {{array?.currencySymbolLeft}}{{array.sallerAmount}}{{array?.currencySymbolRight}}</td>
                </tr>
            </tbody>
        </table>
    </div>


</div>
<div class="showing-results-wrapper">
    <div class="showing-results flex items-center flex-sb">
        <div class="left-part">
            Showing 1-10 of {{this.totalCount}} results
        </div>
    </div>
    <!-- end of container -->

    <!-- pagination -->
    <div class="pager">
        <app-pager (pageChange)="pageChange($event)" [pageSize]="limit" [currentPage]="currentPage" [counts]="this.totalCount"></app-pager>
    </div>
</div>