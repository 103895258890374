<div class="add-bank-wrapper">
    <div class="head-title">
        Add Bank Account  
        <span class="cancel" (click)="closeModal()">
            <img src="../../../../../../assets/images/cancel-icon.svg" alt="">
        </span>
    </div>
   <div class="form-secton">
    <form [formGroup]="addBankFormGroup">
        <div class="form-container">
             <div class="form-content">
                <div class="left-part">
                    <div class="form-wrapper">
                        <div class="form-col">
                            <label for="">Bank Name*</label>
                            <input type="text" placeholder="Enter Bank Name" class="control-input" formControlName="bankName" required (keypress)="checkAlphabet($event)">
                            <span class="text-danger" *ngIf="addBankFormGroup.controls.bankName.touched">
                                <span *ngIf="addBankFormGroup.controls['bankName'].hasError('required')">
                                    Bank name is required* 
                                </span>
                                <span class="" *ngIf="addBankFormGroup.controls.bankName.errors?.['minlength']">
                                    The bank name must be at least five characters long.
                                </span>
                                <span class="" *ngIf="addBankFormGroup.controls.bankName.errors?.['maxlength']">
                                    The bank name cannot exceed 25 characters.
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="form-wrapper">
                        <div class="form-col">
                            <label for="">Account Number*</label>
                            <input type="text" class="control-input" placeholder="Enter Account Number" onpaste="return false;" required formControlName="accNumber" (keypress)="numberOnly($event)">
                            <span class="text-danger" *ngIf="addBankFormGroup.controls.accNumber.touched">
                                <span *ngIf="addBankFormGroup.controls['accNumber'].hasError('required')">
                                    Account number is required* 
                                </span>
                                <span class="" *ngIf="addBankFormGroup.controls.accNumber.errors?.['minlength']">
                                    The account number must be at least 7 digit.
                                </span>
                                <span class="" *ngIf="addBankFormGroup.controls.accNumber.errors?.['maxlength']">
                                    The account number cannot exceed more than 15 digit.
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="form-wrapper">
                        <div class="form-col">
                            <label for="">Routing Number*</label>
                            <input type="text" class="control-input" placeholder="Enter Routing Number" onpaste="return false;" required formControlName="routingNumber" (keypress)="numberOnly($event)">
                            <span class="text-danger" *ngIf="addBankFormGroup.controls.routingNumber.touched">
                                <span *ngIf="addBankFormGroup.controls['routingNumber'].hasError('required')">
                                    Routing Number is required* 
                                </span>
                                <span class="" *ngIf="addBankFormGroup.controls.routingNumber.errors?.['minlength']">
                                    The routing number must be at least 9 digit.
                                </span>
                                <span class="" *ngIf="addBankFormGroup.controls.routingNumber.errors?.['maxlength']">
                                    The routing number cannot exceed more than 9 digit.
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="left-part right-part">
                    <div class="bank-cheque">
                        <label for="">Information</label>
                    <img src="../../../../../../assets/images/bank-cheque.svg" alt="">
                    </div>
                </div>              
             </div>
            <div class="form-action">
                <button class="border-btn cancel" (click)="closeModal()">Cancel</button>
                <button class="primary-btn" type="submit" [disabled]="!addBankFormGroup.valid" (click)="addBankAccount()">
                    Add Account
                </button>
            </div> 
       </div>
    </form>
   </div>
</div>