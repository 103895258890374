<div class="sidebar-menu" [ngClass]="{'asidePurple': getColor('purple'), 'asideGreen': getColor('green'),
'asidePink': getColor('pink'), 'asideOrange': getColor('orange'), 'asideBlue': getColor('blue')}">

    <div class="school-logo">
        <img alt="Dashboard" title="Dashboard" [routerLink]="['/dashboard']" src="assets/imgs/logo-kellton-Commerce.svg">
    </div>

    <ul class="nav navbar-nav">
        <!-- First level menu -->
        <li *ngFor="let sidebarnavItem of menuItem" [routerLinkActive]="sidebarnavItem.path === '' ? '' : 'act-route'" placement="right">
            <a class="flex" *ngIf="!sidebarnavItem.extralink" [routerLink]="sidebarnavItem.path !== '' ? [sidebarnavItem.path] : null"> <img class="nav-img" alt="Nav Img" [src]="sidebarnavItem.image"><img class="nav-act-img" [src]="sidebarnavItem.activeImage"> <span>{{sidebarnavItem.title}}</span></a>
            <a class="flex" [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null" *ngIf="sidebarnavItem.extralink;" (click)="addExpandClass(sidebarnavItem.title)" [routerLinkActive]="sidebarnavItem.path === '' ? '' : 'act-route'">
                <img class="nav-img" alt="Nav Img" [src]="sidebarnavItem.image"> <img class="nav-act-img" alt="Nav Img" [src]="sidebarnavItem.activeImage"><span>{{sidebarnavItem.title}}</span> </a>
            <!-- Second level menu -->
            <div class="sub-menu-item collapse" aria-expanded="false" *ngIf="sidebarnavItem.submenu.length > 0" [ngClass]="{'in-show' : showMenu === sidebarnavItem.title, 'first-level': showMenu !== sidebarnavItem.title}">
                <div *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu" [class.active]="showSubMenu === sidebarnavSubItem.title" [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'">
                    <a [routerLink]="sidebarnavSubItem.submenu.path === '' ? null : [sidebarnavSubItem.path]" [routerLinkActive]="sidebarnavSubItem.path === '' ? '' : 'active'" *ngIf="!sidebarnavSubItem.extralink">
                        {{sidebarnavSubItem.title}}
                    </a>
                </div>
            </div>
        </li>
    </ul>

    <div class="header-right flex">        
    <div class="header-rht__toggle flexx">
        <span><img src="assets/imgs/lite.svg" alt=""></span>
        <input class="tgl tgl-light" id="1aaa" type="checkbox" (click)="darkMode($event.target.checked)" [checked]="themes == 'dark'">
        <label class="tgl-btn" for="1aaa"></label>
        <span class="hrt-dark"><img src="assets/imgs/dark.svg" alt=""><img src="assets/imgs/dark1.svg" alt=""></span>
    </div>

    <div class="profile header-sidebar-profile">
        <div class="dropdown">
            <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span>
                    
                     <img  *ngIf="userProfileDetails?.avatar && userProfileDetails?.avatarPath" class="profile-img" [src]="imageUrl + '?path=' + userProfileDetails.avatarPath + '&name=' + userProfileDetails.avatar + '&width=300&height=300'" alt="">
                     <img  *ngIf="!userProfileDetails?.avatar || !userProfileDetails?.avatarPath" src="assets/imgs/default-profile.png" alt=""> 
                </span><h6 class="user-profile-name">
                    {{userProfileDetails?.firstName | uppercase}}
                </h6>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a class="dropdown-item cursor" [routerLink]="['settings/list']">My Profile</a>
                <a class="dropdown-item cursor" [routerLink]="['settings/list']">Settings</a>
                <a class="dropdown-item cursor" (click)="logout()">Logout</a> 
            </div>
        </div>
    </div> 
    </div>

    <!-- <div class="manage-products">
        <h3>Manage Payments</h3>
        <ul>
            <li routerLinkActive="active"><a href="javascript:void(0)" [routerLink]="['/payments/list']">Payments</a></li>
            <li routerLinkActive="active"><a href="javascript:void(0)" [routerLink]="['/payments/earnings']">Earnings</a></li>
            <li routerLinkActive="active"><a href="javascript:void(0)" [routerLink]="['/payments/archive']">Archive Payments</a></li>
        </ul>
    </div> -->

</div>