import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { AuthSandbox } from '../../../../core/auth/auth.sandbox';
import { CommonSandbox } from '../../../../core/common/common.sandbox';
import { menu } from './side-menu';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {


  menuItem: any;
  showMenu = 'Dashboard';
  showSubMenu = '';
  themes: any = '';
  public userDetails = JSON.parse(sessionStorage.getItem('vendorUserDetails'));
  public userProfileDetails: any;
  public imageUrl: any = '';



  constructor(
    @Inject(DOCUMENT) public document: Document,
    public commonSandbox: CommonSandbox,
    public sandbox: AuthSandbox,
    public router:Router
  ) {}

  ngOnInit() {
    this.addOnConfig();
    this.imageUrl = environment.imageUrl;
    this.menuItem = menu;
    if (sessionStorage.getItem('themes')) {
      this.themes = sessionStorage.getItem('themes');
      if (this.themes == 'dark') {
        this.document.body.classList.replace('light', 'dark');
      }
      if (this.themes == 'light') {
        this.document.body.classList.replace('dark', 'light');
      }
      
    }

    this.getVendorProfile();
    this.getSettings();
    this.commonSandbox.getProfile$.subscribe(profile => {
      if (profile) {
        this.userProfileDetails = profile.customerDetail;
      }
    });
  }

  openDropdown(event) {
    event.stopPropagation();
  }

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  searchMenu(keyword) {
    this.menuItem = menu;
    this.menuItem = this.menuItem.filter(item => {
      return item.title.toLowerCase().includes(keyword);
    });
  }

  getColor(color) {
    if (sessionStorage.getItem('colorPalette') === color) {
      return true;
    } else {
      return false;
    }
  }

  darkMode(e) {
    if (e == true) {
      this.document.body.classList.replace('light', 'dark');
      JSON.stringify(sessionStorage.setItem('themes', 'dark'));
    } else {
      this.document.body.classList.replace('dark', 'light');
      JSON.stringify(sessionStorage.setItem('themes', 'light'));
    }
  }

  logout() {
    this.sandbox.doLogout({});
    sessionStorage.removeItem('vendorUserDetails');
    sessionStorage.removeItem('vendorUser');
    sessionStorage.removeItem('vendor-settings');
    sessionStorage.removeItem('vendorToken');
    this.router.navigate(['/auth/login']);
  }

  getVendorProfile() {
    this.commonSandbox.doGetProfile();
  }

  getSettings() {
    this.commonSandbox.doSettings();
  }

  addOnConfig() {
    this.commonSandbox.addOnConfig({});
  }
}
