import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PayoutService } from '../payout.service';

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})

export class AddCardComponent implements OnInit {

  addCardFormGroup : FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    public dialogRef: MatDialogRef<AddCardComponent>,
    public service : PayoutService,
  ) { }

  ngOnInit(): void {
    this.addCardFormGroup = new FormGroup({
      cardNumber : new FormControl("", [Validators.required, Validators.minLength(16), Validators.maxLength(16)]),
      expDate : new FormControl("", [Validators.required, Validators.minLength(7), Validators.maxLength(15)]),   
      cvvNumber : new FormControl("",[Validators.required, Validators.minLength(3), Validators.maxLength(4)])
    })
  }

  /**
  *  @method closeModal() - Its use for closeing Modal()
  */
  closeModal() {
    this.dialogRef.close();
  }

  /**
  * @method numericAlphaOnly
  * @param event 
  * @returns 
  */
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } 
    return true;
  }

  /**
  * @method -- add card details
  */
  addCardAccount() {
    Object.keys(this.addCardFormGroup.controls).forEach((field) => {
      const control = this.addCardFormGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
    let month = this.addCardFormGroup.value.expDate.slice(0,2);
    let year = this.addCardFormGroup.value.expDate.slice(3,7);
    
    let param : any = {
      accountId : this.data.accountId,
      external_account_type : "card",
      card_number : this.addCardFormGroup.value.cardNumber,
      exp_month : month,
      exp_year : year,
      cvc : this.addCardFormGroup.value.cvvNumber,
    }        
    this.service.addExternalAccountAndCard(param).subscribe((result : any) => {
      if(result.status === 1 && result.result) {
        this.dialogRef.close(result);
      }
    })
  }
}