import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './default/common/layout/layout.component';
import { AuthGuard } from './core/providers/guards/auth-guard';
import { EarningsComponent } from './default/pages/component/sales/manage-accounts/earnings/earnings.component';
import { CommisionsComponent } from './default/pages/component/sales/manage-accounts/commisions/commisions.component';
import { LedgerComponent } from './default/pages/component/sales/manage-accounts/ledger/ledger.component';
import { ShipmentsComponent } from './default/pages/component/sales/manage-accounts/shipments/shipments.component';

const routes: Routes = [

  {
    path: 'auth',
    loadChildren: () => import('./default/auth/auth.module').then(m => m.AuthModule),
    // canActivate: [AuthGuard]
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'catalog',
        loadChildren: () => import('./default/pages/component/catalog/catalog.module').then(m => m.CatalogModule),
      },
      {
        path: 'crm',
        loadChildren: () => import('./default/pages/component/crm/crm.module').then(m => m.CrmModule),
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'sales',
        loadChildren: () => import('./default/pages/component/sales/sales.module').then(m => m.SalesModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./default/pages/component/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./default/pages/component/settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'payments',
        loadChildren: () => import('./default/pages/component/payments/payments.module').then(m => m.PaymentsModule),
      },
      {
        path: 'cms',
        loadChildren: () => import('./default/pages/component/cms/cms.module').then(m => m.CmsModule),
      },
      {
        path: 'marketing',
        loadChildren: () => import('./default/pages/component/marketing/marketing.module').then(m => m.MarketingModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('./default/pages/component/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'earnings',
        component: EarningsComponent,
        data: {
          title: 'Earnings',
          // urls: [{ title: 'Home', url: '/dashboard' },{ title: 'sales', url: '/sales/manage-orders' }, { title: 'Manage accounts', url: '/sales/manage-accounts/list' }, { title: 'Archived Payments' }]
        }
      },
      {
        path: 'commisions',
        component: CommisionsComponent,
        data: {
          title: 'commisions',
          // urls: [{ title: 'Home', url: '/dashboard' },{ title: 'sales', url: '/sales/manage-orders' }, { title: 'Manage accounts', url: '/sales/manage-accounts/list' }, { title: 'Archived Payments' }]
        }
      },
      {
        path: 'ledger',
        component: LedgerComponent,
        data: {
          title: 'ledger',
          // urls: [{ title: 'Home', url: '/dashboard' },{ title: 'sales', url: '/sales/manage-orders' }, { title: 'Manage accounts', url: '/sales/manage-accounts/list' }, { title: 'Archived Payments' }]
        }
      },
      {
        path: 'shipment',
        component: ShipmentsComponent,
        data: {
          title: 'shipments',
          // urls: [{ title: 'Home', url: '/dashboard' },{ title: 'sales', url: '/sales/manage-orders' }, { title: 'Manage accounts', url: '/sales/manage-accounts/list' }, { title: 'Archived Payments' }]
        }
      },
      {
        path: 'payout',
        loadChildren: () => import('./default/pages/component/sales/manage-accounts/payout/payout.module').then(m => m.PayoutModule),
        data: {
          title: 'Payout'
        }


      },

    ],
  }

];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
