<div class="add-delivery">
    <img alt="Manage Delivery Staff" title="Manage Delivery Staff" src="assets/imgs/close.svg" class="close cursor" (click)="close()">
    <span class="head">Manage Delivery Staff </span>
    <div class="main-content flex">
        <div class="content-left">
            <span class="add-title">Add New Delivery Staff </span>
            <div class="cp-lft">

                <div class="cp-group mb50">
                    <p>Name</p>
                    <input type="text" class="input-fields">
                    <label class="switch cursor">
                        <input type="checkbox" type="checkbox" checked>
                        <span class="slider"></span>
                    </label>
                </div>
                <div class="cp-group mb50">
                    <p>Mobile Number</p>
                    <input type="text" class="input-fields">
                </div>
                <div class="cp-group mb50">
                    <p>User Name</p>
                    <input type="text" class="input-fields">
                </div>

                <div class="cp-group mb50">
                    <p>Password</p>
                    <input type="text" class="input-fields" (keydown.space)="$event.preventDefault()">
                </div>
                <div class="flex btn-flex">
                    <button class="ripple add"><span>Add Staff</span></button>
                    <button class="ripple reset"><span>Reset</span></button>
                </div>
            </div>
        </div>

        <div class="content-right">
            <span class="title">52 Delivery Staffs</span>
            <div class="content">
                <input type="text" class="search" placeholder="Delivery person Name">
                <table class="table">
                    <tr>
                        <td class="img">
                            <img alt="Profile" title="Profile" class="cursor" src="assets/imgs/profile-pic.png">
                        </td>
                        <td class="name">Leo Knight
                        </td>
                        <td class="mobile">
                            <img alt="Mobile" title="Mobile" class="cursor" src="assets/imgs/call.svg"> 9845877125
                        </td>
                        <td class="order-detail"> <label class="switch cursor">
                            <input type="checkbox" type="checkbox">
                            <span class="slider"></span>
                        </label></td>
                        <td>
                            <img title="Edit" alt="Edit" class="cursor" src="assets/imgs/edit-dark.svg">
                        </td>
                        <td>
                            <img title="Delete" alt="Delete" class="cursor" src="assets/imgs/trash-dark.svg">
                        </td>
                    </tr>
                    <tr>
                        <td class="img">
                            <img title="Profile" alt="Profile" class="cursor" src="assets/imgs/profile-pic.png">
                        </td>
                        <td class="name">Leo Knight
                        </td>
                        <td class="mobile">
                            <img alt="Mobile" title="Mobile" class="cursor" src="assets/imgs/call.svg"> 9845877125
                        </td>
                        <td class="order-detail"> <label class="switch cursor">
                            <input type="checkbox" type="checkbox">
                            <span class="slider"></span>
                        </label></td>
                        <td>
                            <img title="Edit" alt="Edit" class="cursor" src="assets/imgs/edit-dark.svg">
                        </td>
                        <td>
                            <img title="Delete" alt="Delete" class="cursor" src="assets/imgs/trash-dark.svg">
                        </td>
                    </tr>
                    <tr>
                        <td class="img">
                            <img title="Profile" alt="Profile" class="cursor" src="assets/imgs/profile-pic.png">
                        </td>
                        <td class="name">Leo Knight
                        </td>
                        <td class="mobile">
                            <img title="Mobile" alt="Mobile" class="cursor" src="assets/imgs/call.svg"> 9845877125
                        </td>
                        <td class="order-detail"> <label class="switch cursor">
                            <input type="checkbox" type="checkbox">
                            <span class="slider"></span>
                        </label></td>
                        <td>
                            <img title="Edit" alt="Edit" class="cursor" src="assets/imgs/edit-dark.svg">
                        </td>
                        <td>
                            <img title="Delete" alt="Delete" class="cursor" src="assets/imgs/trash-dark.svg">
                        </td>
                    </tr>
                    <tr>
                        <td class="img">
                            <img title="Profile" alt="Profile" class="cursor" src="assets/imgs/profile-pic.png">
                        </td>
                        <td class="name">Leo Knight
                        </td>
                        <td class="mobile">
                            <img alt="Mobile" title="Mobile" class="cursor" src="assets/imgs/call.svg"> 9845877125
                        </td>
                        <td class="order-detail"> <label class="switch cursor">
                            <input type="checkbox" type="checkbox">
                            <span class="slider"></span>
                        </label></td>
                        <td>
                            <img title="Edit" alt="Edit" class="cursor" src="assets/imgs/edit-dark.svg">
                        </td>
                        <td>
                            <img title="Delete" alt="Delete" class="cursor" src="assets/imgs/trash-dark.svg">
                        </td>
                    </tr>
                    <tr>
                        <td class="img">
                            <img title="Profile" alt="Profile" class="cursor" src="assets/imgs/profile-pic.png">
                        </td>
                        <td class="name">Leo Knight
                        </td>
                        <td class="mobile">
                            <img alt="Mobile" title="Mobile" class="cursor" src="assets/imgs/call.svg"> 9845877125
                        </td>
                        <td class="order-detail"> <label class="switch cursor">
                            <input type="checkbox" type="checkbox">
                            <span class="slider"></span>
                        </label></td>
                        <td>
                            <img title="Edit" alt="Edit" class="cursor" src="assets/imgs/edit-dark.svg">
                        </td>
                        <td>
                            <img title="Delete" alt="Delete" class="cursor" src="assets/imgs/trash-dark.svg">
                        </td>
                    </tr>
                    <tr>
                        <td class="img">
                            <img title="Profile" alt="Profile" class="cursor" src="assets/imgs/profile-pic.png">
                        </td>
                        <td class="name">Leo Knight
                        </td>
                        <td class="mobile">
                            <img alt="Mobile" title="Mobile" class="cursor" src="assets/imgs/call.svg"> 9845877125
                        </td>
                        <td class="order-detail"> <label class="switch cursor">
                            <input type="checkbox" type="checkbox">
                            <span class="slider"></span>
                        </label></td>
                        <td>
                            <img title="Edit" alt="Edit" class="cursor" src="assets/imgs/edit-dark.svg">
                        </td>
                        <td>
                            <img title="Delete" alt="Delete" class="cursor" src="assets/imgs/trash-dark.svg">
                        </td>
                    </tr>
                    <tr>
                        <td class="img">
                            <img alt="Profile" title="Profile" class="cursor" src="assets/imgs/profile-pic.png">
                        </td>
                        <td class="name">Leo Knight
                        </td>
                        <td class="mobile">
                            <img alt="Mobile" title="Mobile" class="cursor" src="assets/imgs/call.svg"> 9845877125
                        </td>
                        <td class="order-detail"> <label class="switch cursor">
                            <input type="checkbox" type="checkbox">
                            <span class="slider"></span>
                        </label></td>
                        <td>
                            <img title="Edit" alt="Edit" class="cursor" src="assets/imgs/edit-dark.svg">
                        </td>
                        <td>
                            <img title="Delete" alt="Delete" class="cursor" src="assets/imgs/trash-dark.svg">
                        </td>
                    </tr>
                    <tr>
                        <td class="img">
                            <img alt="Profile" title="Profile" class="cursor" src="assets/imgs/profile-pic.png">
                        </td>
                        <td class="name">Leo Knight
                        </td>
                        <td class="mobile">
                            <img alt="Mobile" title="Mobile" class="cursor" src="assets/imgs/call.svg"> 9845877125
                        </td>
                        <td class="order-detail"> <label class="switch cursor">
                            <input type="checkbox" type="checkbox">
                            <span class="slider"></span>
                        </label></td>
                        <td>
                            <img title="Edit" alt="Edit" class="cursor" src="assets/imgs/edit-dark.svg">
                        </td>
                        <td>
                            <img title="Delete" alt="Delete" class="cursor" src="assets/imgs/trash-dark.svg">
                        </td>
                    </tr>
                    <tr>
                        <td class="img">
                            <img title="Profile" alt="Profile" class="cursor" src="assets/imgs/profile-pic.png">
                        </td>
                        <td class="name">Leo Knight
                        </td>
                        <td class="mobile">
                            <img alt="Mobile" title="Mobile" class="cursor" src="assets/imgs/call.svg"> 9845877125
                        </td>
                        <td class="order-detail"> <label class="switch cursor">
                            <input type="checkbox" type="checkbox">
                            <span class="slider"></span>
                        </label></td>
                        <td>
                            <img title="Edit" alt="Edit" class="cursor" src="assets/imgs/edit-dark.svg">
                        </td>
                        <td>
                            <img title="Delete" alt="Delete" class="cursor" src="assets/imgs/trash-dark.svg">
                        </td>
                    </tr>
                </table>
            </div>

        </div>
    </div>
</div>