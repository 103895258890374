import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-upcomming-page',
  templateUrl: './upcomming-page.component.html',
  styleUrls: ['./upcomming-page.component.scss']
})
export class UpcommingComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
