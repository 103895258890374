<!-- <router-outlet>
</router-outlet> -->

<div *ngIf="!noInternetConnection">
    <router-outlet></router-outlet>
</div>

<div class="nointernet" *ngIf="noInternetConnection">
    <img src="assets/imgs/nointernet.svg" alt="">
    <h2>Oops!</h2>
    <p>No Internet connection found. <br> Check your connection or try again</p>
</div>