<div class="modal-content">

    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel2">Add Coupon</h4>
        <button type="button" class="close" (click)="close()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body">

        <div class="toggle-group">
            <input class="tgl tgl-light" id="cb1" type="checkbox" />
            <label class="tgl-btn" for="cb1"></label>
            <span>Active</span>
        </div>

        <div class="flex ac-row">

            <div class="ac-left">

                <div class="filter-group">
                    <p>Coupon Name</p>
                    <input type="text">
                </div>

                <div class="filter-group">
                    <p>Coupon Code</p>
                    <input type="text">
                </div>

                <div class="filter-group">
                    <p>Coupon Type</p>
                    <div class="select">
                        <select>
                            <option>Percentage</option>
                            <option>Cost</option>
                        </select>
                    </div>
                </div>

                <div class="filter-group">
                    <p>Value</p>
                    <input type="text">
                </div>

                <div class="filter-group">
                    <p>Minimum Purchase Amount</p>
                    <input type="text">
                </div>

                <div class="filter-group">
                    <p>Applicable For</p>
                    <div class="select">
                        <select>
                            <option>Login Users</option>
                            <option>Guest Users</option>
                        </select>
                    </div>
                </div>



                <div class="ac-col2 flex">
                    <div class="filter-group">
                        <p>Start Date</p>
                        <input type="text">
                    </div>
                    <div class="filter-group">
                        <p>End Date</p>
                        <input type="text">
                    </div>
                </div>

                <div class="filter-group">
                    <p>Maximum Users Per Coupon</p>
                    <input type="text">
                </div>

                <div class="filter-group">
                    <p>No of times the user can use this discount</p>
                    <input type="text">
                </div>

            </div>
            <!--left-->

            <div class="ac-right">

                <div class="select-group">
                    <label class="dropdown">
                        <p>Products applicable for this coupon</p>
                        <div class="dd-button flex">
                            Select Products <img alt="Select Products" title="Select Products" src="assets/imgs/arrow-down.svg">
                        </div>

                        <input type="checkbox" class="dd-input" id="test">

                        <ul class="dd-menu flex">
                            <li>
                                <div class="form-group">
                                    <input type="checkbox" id="al">
                                    <label for="al">Mobiles</label>
                </div>
                </li>
                <li>
                    <div class="form-group">
                        <input type="checkbox" id="al2">
                        <label for="al2">Computers</label>
                    </div>
                </li>
                <li>
                    <div class="form-group">
                        <input type="checkbox" id="al3">
                        <label for="al3">Men</label>
                    </div>
                </li>
                </ul>

                </label>
            </div>
            <div class="selected-products flex">
                <span class="flex">Mobiles <a href="javascript:void(0)">x</a></span>
                <span class="flex">Computers <a href="javascript:void(0)">x</a></span>
                <span class="flex">Men <a href="javascript:void(0)">x</a></span>
            </div>

            <div class="select-group">
                <label class="dropdown">
                        <p>Category not applicable for this coupon</p>
                        <div class="dd-button flex">
                            Select Category <img alt="Select Products" title="Select Products" src="assets/imgs/arrow-down.svg">
                        </div>

                        <input type="checkbox" class="dd-input" id="test">

                        <ul class="dd-menu flex">
                            <li>
                                <div class="form-group">
                                    <input type="checkbox" id="cl">
                                    <label for="cl">Home Appliances</label>
            </div>
            </li>
            </ul>

            </label>
        </div>
        <div class="selected-products sp-not flex">
            <span class="flex">Home Appliances <a href="javascript:void(0)">x</a></span>
        </div>

        <div class="button-group flex">
            <button class="save">Create Coupon</button>
        </div>

    </div>
    <!--Right-->

</div>
<!---->

</div>

</div>