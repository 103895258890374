<div class="style">
  <div class="modal-header">
    <h6 class="modal-title">
      Do you want to delete?
    </h6>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-danger"
      data-dismiss="modal"
    >
      No
    </button>
    <button
      type="button"
      class="btn btn-success"
      data-dismiss="modal"
    >
      Yes
    </button>
  </div>
</div>