<div class="add-bank-wrapper">
    <div class="head-title">
        Add debit card
        <span class="cancel" (click)="closeModal()">
            <img src="../../../../../../assets/images/cancel-icon.svg" alt="">
        </span>
    </div>
   <div class="form-secton">
    <form [formGroup]="addCardFormGroup" autocomplete="off">
        <div class="form-container">
            <div class="form-content">
                <div class="left-part">
                    <div class="form-wrapper">
                        <div class="form-col">
                            <label for="">Card number*</label>
                            <input type="text" class="control-input" placeholder="Enter card number" min="16" max="16" minlength="16" maxlength="16" required formControlName="cardNumber" (keypress)="numberOnly($event)">
                            <span class="text-danger" *ngIf="addCardFormGroup.controls.cardNumber.touched">
                                <span *ngIf="addCardFormGroup.controls['cardNumber'].hasError('required')">
                                    Card number is required* 
                                </span>
                                <span class="" *ngIf="addCardFormGroup.controls.cardNumber.errors?.['minlength']">
                                    The card number must be at least 16 digit.
                                </span>
                                <span class="" *ngIf="addCardFormGroup.controls.cardNumber.errors?.['maxlength']">  
                                    The card number cannot exceed more than 16 digit.
                                </span>
                            </span>
                        </div>
                        <div class="form-col">
                            <label for="">Expiration*</label>
                            <input type="text" class="control-input" placeholder="MM/YYYY" appInputMaskDirective maxlength="7" required formControlName="expDate" (keyup)="numberOnly($event)" autocomplete="new-password">
                            <span class="text-danger" *ngIf="addCardFormGroup.controls.expDate.touched">
                                <span *ngIf="addCardFormGroup.controls['expDate'].hasError('required')">
                                    Exp Date is required* 
                                </span>
                                <span *ngIf="addCardFormGroup.controls.expDate.errors?.['minlength']">
                                    The exp date must be at least 6 digit.
                                </span>
                                <span class="" *ngIf="addCardFormGroup.controls.expDate.errors?.['maxlength']">  
                                    The exp date cannot exceed more than 6 digit.
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="form-wrapper">
                        <div class="form-col">
                            <label for="">CVV*</label>
                            <input type="password" class="control-input" placeholder="Enter here" min="3" max="4" minlength="3" maxlength="4" required formControlName="cvvNumber" (keypress)="numberOnly($event)" autocomplete="new-password">
                            <span class="text-danger" *ngIf="addCardFormGroup.controls.cvvNumber.touched">
                                <span *ngIf="addCardFormGroup.controls['cvvNumber'].hasError('required')">
                                    Cvv number is required* 
                                </span>
                                <span class="" *ngIf="addCardFormGroup.controls.cvvNumber.errors?.['minlength']">
                                    The cvv number must be at least 3 digit.
                                </span>
                                <span class="" *ngIf="addCardFormGroup.controls.cvvNumber.errors?.['maxlength']">  
                                    The cvv number cannot exceed more than 4 digit.
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-action">
            <button type="button" class="border-btn cancel" (click)="closeModal()">Cancel</button>
            <button type="button" class="primary-btn" [disabled]="!addCardFormGroup.valid" (click)="addCardAccount()">Add Card</button>
        </div>
    </form>
</div>