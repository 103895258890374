<header class="flex">
    <div class="hdr-lft flex">
        <h4>Welcome {{userProfileDetails?.firstName}},</h4>
    </div>
    <div class="flex alert-profile">

        <div class="profile">
            <div class="dropdown">
                <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img title="User Profile" alt="User Profile" *ngIf="userProfileDetails?.avatar && userProfileDetails?.avatarPath" class="profile-img" [src]="imageUrl + '?path=' + userProfileDetails.avatarPath + '&name=' + userProfileDetails.avatar + '&width=300&height=300'">
                    <img title="User Profile" alt="User Profile" *ngIf="!userProfileDetails?.avatar || !userProfileDetails?.avatarPath" class="profile-img" src="assets/imgs/default-profile.png">
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <p>Logged in as {{userProfileDetails?.firstName | uppercase}}</p>
                    <a class="dropdown-item" [routerLink]="['/settings']"><img title="Settings" alt="Settings" class="dropdown-img" src="assets/imgs/wallet.svg"> Settings
                    </a>
                    <a class="dropdown-item cursor" (click)="logout()"><i class="fa fa-power-off" aria-hidden="true"></i>
                    Logout</a>
                </div>
            </div>
        </div>
    </div>
</header>

<!-- <div class="color-palatte">
    <a href="javascript:void(0)" class="cp1" (click)="colorPalette('purple')"></a>
    <a href="javascript:void(0)" class="cp2" (click)="colorPalette('grey')"></a>
    <a href="javascript:void(0)" class="cp3" (click)="colorPalette('green')"></a>
    <a href="javascript:void(0)" class="cp4" (click)="colorPalette('pink')"></a>
    <a href="javascript:void(0)" class="cp5" (click)="colorPalette('orange')"></a>
    <a href="javascript:void(0)" class="cp6" (click)="colorPalette('blue')"></a>
</div> -->