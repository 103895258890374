import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { PayoutService } from '../payout.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AddBankAccountComponent } from '../add-bank-account/add-bank-account.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { AddCardComponent } from '../add-card/add-card.component';

@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.scss']
})

export class PayoutComponent implements OnInit {

  accountBalance : number = 0;
  isBankList : boolean = false;
  isPersonal : boolean =  false;
  isPersonalDone : boolean =  false;
  bankList : any = [];
  isBankCheckedCashOut : boolean = false;
  venderDetail : any;
  personalFormGroup: FormGroup;
  isBankChecked : boolean = false;
  isPending : boolean = false;
  isCardChecked : boolean = false;
  selectedMonthId : any = '';
  monthList = [
    { 'id' : 1, 'title' : 'Jan' },  { 'id' : 2, 'title' : 'Feb' },  { 'id' : 3, 'title' : 'Mar'}, 
    { 'id' : 4, 'title' : 'Apr' },  { 'id' : 5, 'title' : 'May' },  { 'id' : 6, 'title' : 'Jun'}, 
    { 'id' : 7, 'title' : 'Jul' },  { 'id' : 8, 'title' : 'Aug' },  { 'id' : 9, 'title' : 'Sep'}, 
    { 'id' : 10, 'title' : 'Oct'},  { 'id' : 11, 'title' : 'Nov' }, { 'id' : 12, 'title' : 'Dec'}
  ];
  selectedDateId :  any = '';
  dayList = [
    { 'id' : 1 },  { 'id' : 2 },  { 'id' : 3 },  { 'id' : 4 },  { 'id' : 5 },  { 'id' : 6 },  { 'id' : 7 }, 
    { 'id' : 8 },  { 'id' : 9 },  { 'id' : 10 }, { 'id' : 11 }, { 'id' : 12 }, { 'id' : 13 }, { 'id' : 14 }, 
    { 'id' : 15 }, { 'id' : 16 }, { 'id' : 17 }, { 'id' : 18 }, { 'id' : 19 }, { 'id' : 20 }, { 'id' : 21 }, 
    { 'id' : 22 }, { 'id' : 23 }, { 'id' : 24 }, { 'id' : 25 }, { 'id' : 26 }, { 'id' : 27 }, { 'id' : 28 },
    { 'id' : 29 }, { 'id' : 30 }, { 'id' : 31 }
  ]
  selectedYearId : any = '';
  yearList = []
  // yearList = [
  //   {'id' : 1947}, {'id' : 1948}, {'id' : 1949}, {'id' : 1950}, {'id' : 1951}, {'id' : 1952}, {'id' : 1953}, 
  //   {'id' : 1954}, {'id' : 1955}, {'id' : 1956}, {'id' : 1957}, {'id' : 1958}, {'id' : 1959}, {'id' : 1960}, 
  //   {'id' : 1961}, {'id' : 1962}, {'id' : 1963}, {'id' : 1964}, {'id' : 1965}, {'id' : 1966}, {'id' : 1967}, 
  //   {'id' : 1968}, {'id' : 1969}, {'id' : 1970}, {'id' : 1971}, {'id' : 1972}, {'id' : 1973}, {'id' : 1974}, 
  //   {'id' : 1975}, {'id' : 1976}, {'id' : 1977}, {'id' : 1978}, {'id' : 1979}, {'id' : 1980}, {'id' : 1981}, 
  //   {'id' : 1982}, {'id' : 1983}, {'id' : 1984}, {'id' : 1985}, {'id' : 1986}, {'id' : 1987}, {'id' : 1988}, 
  //   {'id' : 1989}, {'id' : 1990}, {'id' : 1991}, {'id' : 1992}, {'id' : 1993}, {'id' : 1994}, {'id' : 1995}, 
  //   {'id' : 1996}, {'id' : 1997}, {'id' : 1998}, {'id' : 1999}, {'id' : 2000}, {'id' : 2001}, {'id' : 2002}, 
  //   {'id' : 2003}, {'id' : 2004}, {'id' : 2005}
  // ]
  isValidMonth : boolean = false;
  isValidYear : boolean = false;
  isValidMonthValue : boolean = false;
  addBankFormGroup: FormGroup;
  isChecked : boolean = false;
  addCardFormGroup : FormGroup;
  isCheckedAggrement : boolean = false;
  isCardCheckedCashOut : boolean = false;
  payoutList : any = [];
  cashOutAmount : string = '';
  bankIdNumber : any;
  postalList : any;
  isPostal : boolean = false;
  SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  selectedCountryCode = +1;
  cardList : any = [];
  cardIdNumber : any;
  accountId : any;
  isTerms : boolean = false;
  isCondition : boolean = false;
  placeHolder : string  = '**** **** **** ';

  constructor(
    public service : PayoutService,
    private changeDetectRef: ChangeDetectorRef,
    private toaster: ToastrService,
    public dialog: MatDialog
  ){
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    const currentYear = new Date().getFullYear();
    for (let year = 1940; year <= currentYear; year++) {
      this.yearList.push({'id' : year});
    }
  }

  ngOnInit(): void {
    this.getBalance();
    this.getBankList('isBank');
    
    
    this.personalFormGroup = new FormGroup({
      firstName : new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(25)]),
      lastName : new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(25)]),
      emailAddress : new FormControl(""),
      ssnNumber : new FormControl("", [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
      selectedMonth : new FormControl("", [Validators.required]),
      selectedDate : new FormControl("", [Validators.required]),
      selectedYear : new FormControl("", [Validators.required]),
      phoneNumber : new FormControl("", [Validators.required]),
      addressLine1 : new FormControl("",[Validators.required, Validators.minLength(5), Validators.maxLength(25)]),
      addressLine2 : new FormControl("", [Validators.required, Validators.minLength(5), Validators.maxLength(25)]),
      countryName : new FormControl(""),
      postalCode : new FormControl("",[Validators.required, Validators.minLength(4), Validators.maxLength(5)]),
      cityName : new FormControl(""),
      stateName : new FormControl(""),      
    });

    this.addBankFormGroup = new FormGroup({
      bankName : new FormControl("", [Validators.required, Validators.minLength(5), Validators.maxLength(25)]),
      accNumber : new FormControl("", [Validators.required, Validators.minLength(7), Validators.maxLength(15)]),   
      routingNumber : new FormControl("",[Validators.required, Validators.minLength(9), Validators.maxLength(9)])
    })

    this.addCardFormGroup = new FormGroup({
      cardNumber : new FormControl("", [Validators.required, Validators.minLength(16), Validators.maxLength(16)]),
      expDate : new FormControl("", [Validators.required, Validators.minLength(7), Validators.maxLength(15)]),   
      cvvNumber : new FormControl("",[Validators.required, Validators.minLength(3), Validators.maxLength(4)])
    })
  }

  /**
  * @method -- get user Balance
  */
  getBalance() {
    this.service.getPayoutBalance().subscribe((result : any) => {
      if(result && result.data) {
        this.accountBalance = result.data;
      }
    })
  }

  /**
  * @method -- get Bank List
  */
  getBankList(data)  {
    this.service.listBankAccount().subscribe((result : any) => {
      if(result.status === 0 && result.message === "Vendor Not Found.") {
        this.venderDetails();
        this.isPersonal = true;
      }
      if(result.status === 0 && result.message === "Details missing. Please update the account or contact admin for furthur information.")    {
        this.venderDetails();
        this.isPersonal = true;
      }
      if(result.data) {
        this.accountId = result.data.accountId
      }
      if(result.data && result.data.bankAccount) {
        this.bankList = result.data.bankAccount;
        this.getListPayOut();
        if(data === 'isBank') {
          this.isBankList = true;
          this.isBankCheckedCashOut = true;
          this.isCardCheckedCashOut = false;
        }
      }
      if(result.data && result.data.card) {
        this.cardList = result.data.card;
        this.cardList.forEach((element) => {
          element.last4 = this.placeHolder + element.last4;
        })
        this.getListPayOut();
        if(data === ' isCard') {
          this.isBankList = true;
           this.isBankCheckedCashOut = false;
          this.isCardCheckedCashOut = true;
        }
      }
    })
  }

  /**
  * @method -- vendor details
  */
  venderDetails() {
    debugger
    console.log("vendor function called")
    this.service.vendorDetails().subscribe((result : any) => {
      if(result && result.data) {
        this.venderDetail = result.data;
        this.personalFormGroup.patchValue({
          firstName : result.data.individual?.first_name,
          lastName : result.data.individual?.last_name,
          emailAddress : result.data.email ? result.data.email : result.data.individual?.email,
          ssnNumber : result.data.individual?.ssn_last_4,
          selectedMonth : result.data.individual?.dob?.month,
          selectedDate : result.data.individual?.dob?.day,
          selectedYear : result.data.individual?.dob?.year,
          phoneNumber : result.data.individual?.phone,
          addressLine1 : result.data.individual?.address?.line1,
          addressLine2 : result.data.individual?.address?.line2,
          countryName : result.data.individual?.address?.country,
          postalCode : result.data.individual?.address?.postal_code
        })
        if(result.data.individual?.dob?.month) {
          this.selectedMonthId = result.data.individual?.dob?.month,
          this.selectedDateId = result.data.individual?.dob?.day,
          this.selectedYearId = result.data.individual?.dob?.year
        }
        if(result.data.individual?.address?.postal_code) {
          this.postalCode(result.data.individual?.address?.postal_code, 'notShow');
        }
        if(this.venderDetail.individual?.address?.country === 'US') {
          this.personalFormGroup.patchValue({
            countryName : 'United States',
          })          
        }
        if(this.venderDetail.individual?.ssn_last_4) {
          this.isPersonalDone = true;
          this.isPersonal = false;
          this.isBankChecked = true;
        }
      }
    })
  }

  /**
  *@method --  get ListPayOut
  */
  getListPayOut() {
    this.service.getPayoutList().subscribe((result : any) => {
      if(result && result.data) {
        this.payoutList = result.data;
      }
    })
  }

  /**
  * @method -- selected Paypal
  */ 
  selectedCard() {
    this.isCardChecked = true;
    this.isBankChecked = false;
  }

  /**
  * @method -- selected Paypal
  */
  selectedBank() {
    this.isBankChecked = true;
    this.isCardChecked = false;
  }

  /**
  * @method -- check alphabet or not
  * @param event 
  * @returns 
  */
  characterOnly(event) {
    if ((event.keyCode > 64 && event.keyCode < 91) || (event.keyCode > 96 && event.keyCode < 123) || (event.keyCode == 8) || event.keyCode == 32) {
      return true;
    }
    else  {
      return false;
    }
  }

  /**
  * @method numberOnly -- accept only number
  * @param event 
  * @returns 
  */
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } 
    return true;
  }

  /**
  * @method -- on Country Change
  * @param event 
  */
  onCountryChange(event)  {
    this.selectedCountryCode = event.dialCode;
  }

  /**
  * @method -- selected month
  * @param event 
  */
  selectChangeMonth(event) {
    this.selectedMonthId = event.target.value;
  }

  /**
  * @method -- selected date
  * @param event 
  */
  selectChangeDate(event) {
    this.selectedDateId = event.target.value;
    if(this.selectedMonthId === '2') {
      if(this.selectedDateId == 30 || this.selectedDateId == 31) {
        this.isValidMonth = true;
        this.isValidMonthValue = false;
      }
      else {
        this.isValidMonth = false;
      }
    }
    else if(this.selectedMonthId === '4' || this.selectedMonthId === '6' || 
      this.selectedMonthId === '9' || this.selectedMonthId === '11') {
      if(this.selectedDateId == 31) {
        this.isValidMonthValue = true;
        this.isValidMonth = false;
      }
      else {
        this.isValidMonthValue = false;
      } 
    }
  }

  /**
  * @method -- selected year
  * @param event 
  */
  selectChangeYear(event) {
    this.selectedYearId = event.target.value;
    if(this.selectedMonthId === '2') {
      if((this.selectedYearId % 4 == 0) && (this.selectedYearId % 100 != 0) || (this.selectedYearId % 400 === 0) && (this.selectedDateId > 28)) {
        this.isValidYear = false;
      }
      else {
        if(this.selectedDateId > 29) {
          this.isValidYear = true;
        }
      }
    }
  }

  /**
  * @method -- accept number only
  * @param event 
  * @returns 
  */
  numberOnlyPostalCode(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } 
    if(event.currentTarget.value.length >= 2) {
      this.postalCode(event.currentTarget.value, 'show');
    }
    return true;
  }

  /**
  * @method -- postel code
  * @param postel 
  */
  postalCode(postel : any, value : any) {
    this.service.getPostalCode(postel).subscribe((result : any) => {
      if(result.response) {
        if(value === 'notShow') {
          this.isPostal = false;
          this.postalList = result.response;
          this.updateValue(postel);
        }
        else {
          this.isPostal = true;
          this.postalList = result.response;
        }
      }
    })
  }

  /**
   * @method -- on change
   * @param event 
   */
  selectChangePostel(postel) {
    this.updateValue(postel.code);
  }

  /**
   * @method -- update value
   * @param id 
   */
  updateValue(id) {
    this.personalFormGroup.patchValue({
      postalCode : id
    })
    let result = this.postalList.filter(postel => postel.code == id);
    this.personalFormGroup.patchValue({
      stateName : result[0].zone.name,
      cityName : result[0].cityName,
    })
    this.isPostal = false;
  }

  /**
  * @method -- submit all personal information
  */
  submitInformation() {
    Object.keys(this.personalFormGroup.controls).forEach((field) => {
      const control = this.personalFormGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });

    if(this.personalFormGroup.value.phoneNumber.number.length < 10) {
      this.toaster.error('Please enter minimum 10 digit');
    }
    else {
      let result = this.postalList.filter(postel => postel.code == this.personalFormGroup.value.postalCode);
      let state = result.zone?.code ? result[0].zone?.code : result[0].zoneId;
      // let state = "AK"
    
      let params : any  = {
        first_name : this.personalFormGroup.value.firstName,
        last_name : this.personalFormGroup.value.lastName,
    //    country_code : this.personalFormGroup.value.phoneNumber.dialCode,
        phone_number : this.personalFormGroup.value.phoneNumber.number,
        last_4_ssn : this.personalFormGroup.value.ssnNumber,
        day : this.personalFormGroup.value.selectedDate,
        month : this.personalFormGroup.value.selectedMonth,
        year : this.personalFormGroup.value.selectedYear,   
        city : this.personalFormGroup.value.cityName, 
        address_1 : this.personalFormGroup.value.addressLine1,
        address_2 : this.personalFormGroup.value.addressLine2,
        postal_code : this.personalFormGroup.value.postalCode,
        state : state
      };
      this.service.updateVendorDetails(params).subscribe((result : any) => {
        if(result.status === 1 && result.message === 'Updated Successfully.') {
          this.isPersonalDone = true;
          this.isPersonal = false;
          this.isBankChecked = true;
          this.venderDetails();
         }
      })
    }
  }

  /**
   * @method -- changeEvent
   * @param event 
   */
  changeEvent(event) {
    this.isChecked = event.target.checked;
  }

  /**
  * @method -- go to previous page
  */
  previousDetails() {
    this.isPersonal = true;
    this.isPersonalDone = false;
    this.isBankChecked = false;
  }

  /**
   * @method -- read terms and condition
   */
  terms() {
    this.isTerms = true;
    let url = 'https://stripe.com/in/legal/connect-account';
    window.open(url, '_blank');
  }

  /**
  * @method -- add bank account
  */
  addBankAccount() {
    if(!this.isChecked) {
      this.toaster.error('Please check service aggrement');
    }
    else {
      Object.keys(this.addBankFormGroup.controls).forEach((field) => {
        const control = this.addBankFormGroup.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
      let param : any = {
        ssn_last_digit :  this.venderDetail.individual?.ssn_last_4,
        firstName : this.venderDetail.individual?.first_name,
        day : this.venderDetail.individual?.dob?.day,
        month : this.venderDetail.individual?.dob?.month,
        year : this.venderDetail.individual?.dob?.year,
        lastName : this.venderDetail.individual?.last_name,
        //  country_code : this.venderDetail.individual?.last_name,
        mobileNumber : this.venderDetail.individual?.phone,
        city : this.venderDetail.individual?.address?.city,
        state : this.venderDetail.individual?.address?.state,
        // state : "AK",
        country : this.venderDetail.individual?.address?.country,
        address1 : this.venderDetail.individual?.address?.line1,
        address2 : this.venderDetail.individual?.address?.line2,
        postcode : this.venderDetail.individual?.address?.postal_code,
        email : this.venderDetail.individual?.email,
        mcc: '5734',
        url: "https://dev.blazing-card-store-dev.kellton.net",
        business_type: 'individual',
        bankName : this.addBankFormGroup.value.bankName,
        account_number : this.addBankFormGroup.value.accNumber,
        routing_number :  this.addBankFormGroup.value.routingNumber,
        external_account_type : 'bank'
      }
      this.service.addBank(param).subscribe((result) => {
        if(result.status === 1 && result.result) {
          this.isPending = true;
          this.isPersonal = false;
          this.isPersonalDone = false;
          this.isBankChecked = false;
        }
      })
    }      
  }

  /**
  * @method -- changeEvent Aggrement
  * @param event 
  */
  changeEventAggrement(event) {
    this.isCheckedAggrement = event.target.checked;
  }

  /**
   * @method -- previous from Card
   */
  previousCardDetails() {
    this.isPersonal = true;
    this.isPersonalDone = false;
    this.isCardChecked = false;
  }

  /**
  * @method numberOnly -- accept only number
  * @param event F
  * @returns 
  */
  numberOnlyExp(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57)) {
      return false;
    } 
    return true;
  }

  /**
   * @method -- for terms and condition
   */
  condition() {
    this.isCondition = true;
    let url = 'https://stripe.com/in/legal/connect-account';
    window.open(url, '_blank');
  }

  /**
  * @method -- add card details
  */
  addCardAccount() {
    if(!this.isCheckedAggrement) {
      this.toaster.error('Please check service aggrement');
    }
    else {
      Object.keys(this.addCardFormGroup.controls).forEach((field) => {
        const control = this.addCardFormGroup.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
      let month = this.addCardFormGroup.value.expDate.slice(0,2);
      let year = this.addCardFormGroup.value.expDate.slice(3,7);
      let param : any = {
        business_type : "individual",
        ssn_last_digit : this.venderDetail.individual?.ssn_last_4,
        day : this.venderDetail.individual?.dob?.day,
        month : this.venderDetail.individual?.dob?.month,
        year : this.venderDetail.individual?.dob?.year,
        mcc: '5734',
        url: "https://dev.blazing-card-store-dev.kellton.net",
        firstName : this.venderDetail.individual?.first_name,
        lastName : this.venderDetail.individual?.last_name,
        //  country_code : this.venderDetail.individual?.last_name,
        mobileNumber : this.venderDetail.individual?.phone,
        city : this.venderDetail.individual?.address?.city,
        state : this.venderDetail.individual?.address?.state,
        country : this.venderDetail.individual?.address?.country,
        address1 : this.venderDetail.individual?.address?.line1,
        address2 : this.venderDetail.individual?.address?.line2,
        postcode : this.venderDetail.individual?.address?.postal_code,
        email : this.venderDetail.individual?.email,
        external_account_type : "card",
        card_number : this.addCardFormGroup.value.cardNumber,
        exp_month : month,
        exp_year : year,
        cvc : this.addCardFormGroup.value.cvvNumber
      }    
      this.service.addBank(param).subscribe((result) => {
        if(result.status === 1 && result.result) {
          this.isPending = true;
          this.isPersonal = false;
          this.isPersonalDone = false;
          this.isCardChecked = false;
        }
      })
    }
  }

  /**
  * @method -- selected Paypal
  */
  selectedBankCashOut() {
    this.isBankCheckedCashOut = true;
    this.isCardCheckedCashOut = false;
  }

  /**
  * @method -- selected Paypal
  */ 
  selectedCardCashOut() {
    this.isCardCheckedCashOut = true;
    this.isBankCheckedCashOut = false;
  }

  /**
  * @method -- add Bank Account
  */
  addAnotherBankAccount() {
    this.dialog.open(AddBankAccountComponent, {
      width: '400px',
      height: '300px',
      data: { 
        accountId : this.accountId
      }
    }).afterClosed().subscribe((result) => { 
      if(result) {
        this.isBankCheckedCashOut = true;
        this.getBankList('isBank');
      }
    });
  }

  /**
  * @method -- add Card Account
  */
  addAnotherCardAccount() {
    this.dialog.open(AddCardComponent, {
      width: '400px',
      height: '300px',
      data: { 
        accountId : this.accountId
      }
    }).afterClosed().subscribe((result) => { 
      if(result) {
        this.isCardCheckedCashOut = true;
        this.getBankList('isCard');
      }
    });
  }

  /**
  * @method -- on BankList Change
  * @param event 
  */
   placeHolderName : any = "Select Bank Account"
  onBankListChange(item) {
    
    this.bankIdNumber = item.id;
    this.placeHolderName = item.bankName+ "-"+ item.last4
    this.changeDetectRef.detectChanges();
  }
  
  /**
  * @method -- on BankList Change
  * @param event 
  */
  onCardListChange(item) {
    this.cardIdNumber = item.id;
  }

  /**
  * @method -- cashOut PayPal
  */ 
  cashOutCard() {
    if(!this.cardIdNumber) {
      this.toaster.error('Please select card');
    }
    if(!this.cashOutAmount) {
      this.toaster.error('Please provide cash out amount');
    }
    if(this.cashOutAmount && this.cardIdNumber) {
      let params : any = {
        amount  : this.cashOutAmount,
        type : 'Card'
      }
      this.service.cashOutPayout(params).subscribe((result : any) => {
        if(result && result.data) {
          this.getListPayOut();
          this.getBalance();
          this.cashOutAmount = '';
        }
        else {
          this.toaster.error(result.message);
          this.cashOutAmount = '';
        }
      })
    }
  }

  /**
  * @method -- cashOut Bank
  */
  cashOutBank() {
    if(!this.cashOutAmount) {
      this.toaster.error('Please provide cash out amount');
    }
    if(!this.bankIdNumber) {
      this.toaster.error('Please select bank account');
    }
    if(this.cashOutAmount && this.bankIdNumber) {
      let params : any = {
        amount  : this.cashOutAmount,
        type : 'Bank'
      }
      this.service.cashOutPayout(params).subscribe((result : any) => {
        if(result && result.data) {
          this.getListPayOut();
          this.getBalance();
          this.cashOutAmount = '';
        }
        else {
          this.toaster.error(result.message);
          this.cashOutAmount = '';
        }
      })
    }
  }

  /**
  * @method -- delete bank account
  * @param - item
  */
  deleteBankAccount(item) {
    let params : any = {
      accountId  : this.accountId,
      externalBankCardId : item.id
    }
    this.service.bankAccountDelete(params).subscribe((result : any) => {
      if(result.data && result.status === 1) {
        this.isBankCheckedCashOut = false;
        this.getBankAndCardList('isBank');
      }
    })
  }

  /**
  * @method -- delete Card
  * @param item 
  */
  deleteCard(item) {
    let params : any = {
      accountId  : this.accountId,
      externalBankCardId : item.id
    }
    console.log(params);
    this.service.bankAccountDelete(params).subscribe((result : any) => {
      if(result.data && result.status === 1) {
        this.isCardCheckedCashOut = false;
        this.getBankAndCardList('isCard');
      }
    })
  }

  /**
  * @method -- get Bank List
  */
  getBankAndCardList(data) {
    this.service.listBankAccount().subscribe((result : any) => {
      if(result.data) {
        this.accountId = '';
        this.accountId = result.data.accountId
      }
      if(result.data && result.data.bankAccount) {
        this.bankList = [];
        this.bankList = result.data.bankAccount;
        if(data === 'isBank') {
          this.isBankList = true;
          this.isBankCheckedCashOut = true;
          this.isCardCheckedCashOut = false;
        }
      }
      if(result.data && result.data.card) {
        this.cardList = [];
        if(data === 'isCard') {
          this.cardList = result.data.card;
          this.cardList.forEach((element) => {
            element.last4 = this.placeHolder + element.last4;
          })
          this.isBankList = true;
          this.isBankCheckedCashOut = false;
          this.isCardCheckedCashOut = true;
        }
      }
    })
  }
}
