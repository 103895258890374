import { Injectable } from '@angular/core';
import { Api } from '../../../../../../core/providers/api/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PayoutService extends Api {

  private baseUrl: string = this.getBaseUrl();

  /**
  * @method -- get payout balance
  * @returns 
  */
  getPayoutBalance() {
    return this.http.get(this.baseUrl + '/payout/balance');
  }

  /**
  * @method -- list of bank account
  * @returns 
  */
  listBankAccount() {
    return this.http.get(this.baseUrl + '/payout/get-connected-account');
  }

  /**
  * @method -- vendor details
  * @returns 
  */
  vendorDetails() {
    debugger
    console.log("vendor service called")
    return this.http.get(this.baseUrl + '/payout/vendor-Details');
  }

  /**
  * @method -- update vendor details
  * @param param 
  * @returns 
  */
  public updateVendorDetails(param: any): Observable<any> {
    return this.http.post(this.baseUrl + '/payout/update-vendor-Details', param);
  }

  /**
  * @methd -- add bank account
  * @param param 
  * @returns 
  */
  public addBank(param: any): Observable<any> {
    return this.http.post(this.baseUrl + '/payout/create-vendor-account', param);
  }

  /**
  * @method -- get payout list
  * @returns 
  */
  getPayoutList() {
    return this.http.get(this.baseUrl + '/payout/payout-request-list');
  }

  /**
  * @method - cash out from bank
  * @param param 
  * @returns 
  */
  cashOutPayout(param : any) {
    return this.http.post(this.baseUrl + '/payout/request-payout', param);
  }

  /**
  * @method -- get Postal Code
  * @param zipCode 
  * @returns 
  */
  getPostalCode(zipCode) {
    return this.http.get(this.baseUrl + '/location/zip-to-details?zip=' +zipCode);
  }

  /**
  * @method -- get Postal Code
  * @param zipCode 
  * @returns 
  */
  addExternalAccountAndCard(param) {
    return this.http.post(this.baseUrl + '/payout/add-vendor-external-account', param);
  }

  /**
  * @method --  bank Account Delete
  * @param id 
  * @returns 
  */
  bankAccountDelete(params) {
    return this.http.post(this.baseUrl + '/payout/delete-external-account', params);
  }
}