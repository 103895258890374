
export const menu = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    image: 'assets/imgs/header-icons/dashboard.svg',
    activeImage: 'assets/imgs/header-icons/dashboard-on.svg',
    submenu: [],
    extralink: false,
    class: ''
  },
  {
    path: '/sales',
    title: 'Sales',
    image: 'assets/imgs/header-icons/MainSales.svg',
    activeImage: 'assets/imgs/header-icons/MainSales-on.svg',
    submenu: [],
    class: '',
    extralink: false
  },
  {
    path: '/catalog',
    title: 'Catalog',
    image: 'assets/imgs/header-icons/MainCatelog.svg',
    activeImage: 'assets/imgs/header-icons/MainCatelog-on.svg',
    submenu: [],
    class: '',
    extralink: false
  },

  {
    path: '/crm',
    title: 'CRM',
    image: 'assets/imgs/header-icons/MainCustomers.svg',
    activeImage: 'assets/imgs/header-icons/MainCustomers-on.svg',
    submenu: [],
    class: '',
    extralink: false
  },
  {
    path: '/marketing',
    title: 'Marketing',
    image: 'assets/imgs/header-icons/MainMarketing.svg',
    activeImage: 'assets/imgs/header-icons/MainMarketing-on.svg',
    submenu: [],
    class: '',
    extralink: false
  },
  {
    path: '/reports',
    title: 'Report',
    image: 'assets/imgs/header-icons/MainAuditlog.svg',
    activeImage: 'assets/imgs/header-icons/MainAuditlog-on.svg',
    submenu: [],
    class: '',
    extralink: false
  },

  {
    path: '/shipment',
    title: 'Shipments',
    image: 'assets/imgs/header-icons/MainSales.svg',
    activeImage: 'assets/imgs/header-icons/MainSales-on.svg',
    submenu: [],
    class: '',
    extralink: false
  },
  {
    path: '/commisions',
    title: 'Commision',
    image: 'assets/imgs/header-icons/earnings.svg',
    activeImage: 'assets/imgs/header-icons/earnings-on.svg',
    submenu: [],
    class: '',
    extralink: false
  },
  {
    path: '/ledger',
    title: 'ledger',
    image: 'assets/imgs/header-icons/earnings.svg',
    activeImage: 'assets/imgs/header-icons/earnings-on.svg',
    submenu: [],
    class: '',
    extralink: false
  },
  {
    path: '/payout',
    title: 'Payout',
    image: 'assets/imgs/header-icons/earnings.svg',
    activeImage: 'assets/imgs/header-icons/earnings-on.svg',
    submenu: [],
    class: '',
    extralink: false
  }
];
