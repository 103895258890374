<div>
    <div class="modal-header">
        <h6 class="modal-title"> Do you want change this order status?</h6>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="dismiss()" data-dismiss="modal">No
    </button>
        <button type="button" class="btn btn-success" (click)="accept()" data-dismiss="modal">Yes
    </button>
    </div>
</div>